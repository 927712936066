import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, FormGroup, Row, Col } from 'reactstrap';

import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { toast } from 'react-toastify';

const Login = () => {

  const { loginUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(true);

  const toggle = () => {
    setIsLoginModalOpen(isLoginModalOpen)
  }

  let history = useHistory();

  function qbAuthFlow(token){
    axios.get(process.env.REACT_APP_SERVER_URL + '/authUri', {
      headers:{
        token: token
      }
    })
      .then(res => {
        window.location.href = res.data;
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  // useEffect(qbAuthFlow(), [])

  const [password, setPassword] = useState('');

  const preToggle = () => {
    setPassword('');
    toggle()
  }

  function login(){
    loginUser({
      password
    })
    .then(res => {
      history.push('/labels')
    })
    .catch(err => toast.error('Error'))
    .finally(() => setLoading(false))
  }

  if(loading){
    return(
    <div className="preloader">
      <div className="status">
          <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div>
  </div>
  )}

  return(
    <Modal isOpen={isLoginModalOpen}>
            <ModalHeader>
                Login
            </ModalHeader>
            <ModalBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Enter password
                      </Label>
                      <Input
                      type='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter password"
                      >
                      
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter >
                <Button color="primary" onClick={login}>
                    Login
                </Button>{' '}
            </ModalFooter>
        </Modal>
  )
}

// const LoginModal = ({ isOpen, toggle, qbAuthFlow }) => {
  
//   const [password, setPassword] = useState('');

//   const preToggle = () => {
//     setPassword('');
//     toggle()
//   }

//   function loginUser(){
//     axios.post(process.env.REACT_APP_SERVER_URL + '/login')
//     .then(res => {
//       qbAuthFlow()
//     })
//   }

//   return(
//     <Modal isOpen={isOpen}>
//             <ModalHeader>
//                 Login
//             </ModalHeader>
//             <ModalBody>
                
//             </ModalBody>
//             <ModalFooter >
//                 <Button color="primary" onClick={saveProduct}>
//                     Login
//                 </Button>{' '}
//             </ModalFooter>
//         </Modal>
//   )
// }

export default Login;
