import React, { useState, useRef, useEffect } from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

function ErrorFallbackModal({error, resetErrorBoundary}) {

    let history = useHistory();
  
    const [seconds, setSeconds] = useState(10);
  
    const sessionTimeoutRef = useRef(null);
  
    const modalTimeoutRef = useRef(null);

    console.log(error.name)

    console.log(error.message)

    console.log(error.stack)

    const goBack = () => {
        history.goBack()
        // resetErrorBoundary()
        // clearTimeout(sessionTimeoutRef.current)
        // clearTimeout(modalTimeoutRef.current)
        // history.goBack()
    }

    useEffect(() => {
        sessionTimeoutRef.current = setTimeout(() => goBack(), seconds * 1000);

        return () => clearTimeout(sessionTimeoutRef.current)
    }, [])
  
    useEffect(() => {
  
        modalTimeoutRef.current =  setTimeout(() => {
            setSeconds(seconds -1);
        }, 1000)
  
        // return () => {
        //     clearTimeout(sessionTimeoutRef.current)
        //     clearTimeout(modalTimeoutRef.current)
        // }

        return () => clearTimeout(modalTimeoutRef.current)
  
    }, [seconds])

    const [timerState, setTimerState] = useState(true)

    const pauseTimer = () => {
        clearTimeout(modalTimeoutRef.current)
        clearTimeout(sessionTimeoutRef.current)
        setTimerState(false)
    }

    const resumeTimer = () => {
        modalTimeoutRef.current =  setTimeout(() => {
            setSeconds(seconds -1);
        }, 1000)

        sessionTimeoutRef.current = setTimeout(() => goBack(), seconds * 1000);

        setTimerState(true)
    }
  
    return (
    <Modal
        isOpen
        // toggle={this.toggle}
        // className={this.state.className}
        >
        <ModalHeader>Uh Oh</ModalHeader>
        <ModalBody>
            <p>Oops, an error has occured:</p>
            <p><b>{error.name}: {error.message}</b></p>
            <p>You will be redirected in <strong>{seconds}</strong> seconds</p>
            <Button onClick={timerState ? pauseTimer : resumeTimer}>{timerState ? 'Pause' : 'Resume'} Timer</Button>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={goBack}>
                Go Back
            </Button>{' '}
        </ModalFooter>
    </Modal>
    )
  }

  export default ErrorFallbackModal;