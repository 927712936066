import logo from './logo.svg';
import './App.css';

// import './assets/scss/Saas.scss';
// import './assets/scss/Modern.scss';
import './assets/scss/Creative.scss';

// import './assets/scss/Saas-Dark.scss';
// import './assets/scss/Modern-Dark.scss';
// import './assets/scss/Creative-Dark.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import LabelView from './components/LabelView';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Header from './layout/Header';
import QtyButton from './components/QtyButton';
import PrivateRoute from './components/PrivateRoute';
import Report from './components/Report';
import ItemGroupDetail from './components/ItemGroupDetail';
import Products from './components/Products';
import { UserProvider } from './context/UserContext';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <UserProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/group" component={ItemGroupDetail} />
            <PrivateRoute component={LabelView} path="/labels" exact />
            <Route exact path='/today' component={Report} />
            {/* <Route exact path="/labels" component={LabelView} /> */}
            {/* <Redirect to="/labels" /> */}
            </Switch>
            <ToastContainer
            position="bottom-center"
            theme="light"
            autoClose={2000}
            hideProgressBar={false}
            transition={Slide}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            icon
            draggable
            limit={5}
          />
          </UserProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
