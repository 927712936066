import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import cloneDeep from 'lodash.clonedeep';
import Fuse from "fuse.js";
import chevronDoubleLeft from '@iconify/icons-mdi/chevron-double-left';
import chevronDoubleRight from '@iconify/icons-mdi/chevron-double-right';
import chevronLeft from '@iconify/icons-mdi/chevron-left';
import chevronRight from '@iconify/icons-mdi/chevron-right';

const Products = () => {

    const [products, setProducts] = useState([]);

    const [stations, setStations] = useState('');

    const [searchTerm, setSearchTerm] = useState('');

    const [searchResults, setSearchResults] = useState([]);

    const [isProductModalOpen, setIsProductModalOpen] = useState(false);

    const [paginationValues, setPaginationValues] = useState({
        page: 1,
        sizePerPage: 10
    })

    const toggleProductModal = () => {
        setIsProductModalOpen(!isProductModalOpen)
    }

    const fuse = new Fuse(products);

    useEffect(() => {
        if(products.length){
            if(searchTerm){
            const fuse = new Fuse(products, {
                keys: ['name']
            })
            setSearchResults(fuse.search(searchTerm))
        } else {
            setSearchResults(products.map((prod) => {
                return {item: prod}
            }))
        }
        }
    }, [searchTerm, products]) 

    useEffect(() => {
        axios.get('/products')
        .then(res => {
            setProducts(res.data.sort((a, b) => (a.id > b.id) ? 1 : -1))
        })
        .catch(e => console.log(e))

        axios.get('/stations')
        .then(res => {
            setStations(res.data)
        })
        .catch(e => console.log(e))
    }, [])

    const setStation = (rowId, newValue) => {
        axios.put('/products/' + rowId, {
            id: rowId,
            station_id: newValue
        })
        .then(res => {
            // let tempProducts = products;
            // tempProducts.find(prod => prod.id === product.id).station_id = e.value
            const newProducts = products.map(prod => {
                if (prod.id === rowId) {
                  return { ...prod, station_id: newValue };
                }
                return {...prod};
              });
            // let tempProducts = cloneDeep(products)
            // console.log(tempProducts)
            toast.success('Saved');
            setProducts(newProducts)
        })
        .catch(err => {
            toast.error('Error');
        })
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => (
                <b>{cell}</b>
            )
        },
        {
            dataField: 'station_id',
            text: 'Station',
            sort: false,
            formatter: (cell, row) => (
                <Select
                className="react-select"
                classNamePrefix="react-select"
                isSearchable={false}
                hideSelectedOptions
                onChange={(e) => setStation(row.id, e.value)}
                value={{value: row.station_id, label: stations.find(s => s.id === row.station_id).name}}
                options={
                    stations.map(option => (
                        { value: option.id, label: option.name }
                    ))
                }></Select>
            )
        }
    ];
    
    const defaultSorted = [
        {
            dataField: 'id',
            order: 'asc',
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const paginationOptions = {
        paginationSize: 25,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '25',
                value: 25,
            },
            {
                text: '50',
                value: 50,
            },
            {
                text: '100',
                value: 100,
            },
            {
                text: 'All',
                value: products.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    console.log(paginationValues)

    if(products && stations)
    return (
        <>
        <div className='mt-3 mx-4'>
        <h2>Product List</h2>
        <Card>
            <CardBody>
                {/* <h4 className="header-title">Product List</h4>
                <p className="text-muted font-14 mb-4">A list of all existing Greenbowl Products</p> */}
                <Row className="mb-2">
                    <Col>
                    <Button className="btn-danger" onClick={toggleProductModal}>
                        <i className="mdi mdi-plus-circle mr-2"></i>Add Product
                    </Button>
                    </Col>
                    <Col className="text-right">
                        <FormGroup className='d-flex mb-0 align-items-center'>
                            <Label className='mr-2 mb-0'><b>Search:</b></Label>
                        <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}>
                        </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Table className="mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Station</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults
                        .slice((paginationValues.page - 1) * paginationValues.sizePerPage, paginationValues.page * paginationValues.sizePerPage)
                        .map(({item}, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row" style={{ verticalAlign: 'middle' }}>{item.id}</th>
                                    <td style={{ verticalAlign: 'middle' }}>{item.name}</td>
                                    <td><Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        hideSelectedOptions
                                        onChange={(e) => setStation(item.id, e.value)}
                                        value={{value: item.station_id, label: stations.find(s => s.id === item.station_id).name}}
                                        options={
                                            stations.map(option => (
                                                { value: option.id, label: option.name }
                                            ))
                                        }></Select></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <Row className='d-flex justify-content-between align-items-center mt-2 mx-1'>
                    <div className='d-flex align-items-center'>
                    <div style={{ width: '80px' }} className="mr-2">
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        onChange={(e) => {
                            console.log(e.value)
                            setPaginationValues({
                                ...paginationValues,
                                sizePerPage: e.value
                            })}}
                        value={{value: paginationValues.sizePerPage, label: paginationValues.sizePerPage}}
                        options={
                            [
                                {value: 5, label: 5},
                                {value: 10, label: 10},
                                {value: 25, label: 25},
                                {value: 50, label: 50},
                                {value: 75, label: 75},
                                {value: 100, label: 100}
                            ]
                        }></Select>
                        </div>
                        <div className=''>
                            Showing <strong>{((paginationValues.page - 1) * paginationValues.sizePerPage) + 1}</strong> to <strong>{Math.min(paginationValues.page * paginationValues.sizePerPage, searchResults.length)}</strong> of <strong>{searchResults.length}</strong> Results
                        </div>
                    </div>
                    <Pagination>
                        <PaginationItem
                        disabled={Math.ceil(searchResults.length / paginationValues.sizePerPage) < 2 || paginationValues.page < 2}
                        >
                        <PaginationLink 
                        className='d-flex align-items-center'
                        onClick={() => setPaginationValues({
                            ...paginationValues,
                            page: 1
                        })}>
                            <Icon icon={chevronDoubleLeft} width="24" height="24" />
                            First
                        </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={paginationValues.page < 2}>
                        <PaginationLink 
                        className='d-flex align-items-center'
                        onClick={() => setPaginationValues({
                            ...paginationValues,
                            page: paginationValues.page - 1
                        })}>
                            <Icon icon={chevronLeft} width="24" height="24" />
                            Previous
                        </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={Math.ceil(searchResults.length / paginationValues.sizePerPage) === paginationValues.page}>
                        <PaginationLink 
                        className='d-flex align-items-center'
                        onClick={() => setPaginationValues({
                            ...paginationValues,
                            page: paginationValues.page + 1
                        })}>
                            Next
                            <Icon icon={chevronRight} width="24" height="24" />
                        </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                        disabled={Math.ceil(searchResults.length / paginationValues.sizePerPage) < 2 || paginationValues.page === Math.ceil(searchResults.length / paginationValues.sizePerPage)}
                        >
                        <PaginationLink 
                        className='d-flex align-items-center'
                        onClick={() => {

                            let totalPages = searchResults.length / paginationValues.sizePerPage
                            
                            setPaginationValues({
                            ...paginationValues,
                            page: Math.ceil(totalPages)

                        })

                        }}>
                            Last
                            <Icon icon={chevronDoubleRight} width="24" height="24" />
                        </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </Row>

                {/* <BootstrapTable
                    striped
                    bootstrap4
                    keyField="id"
                    data={products}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    pagination={paginationFactory(paginationOptions)}
                    wrapperClasses="table-responsive"
                /> */}
            </CardBody>
        </Card>
        <AddProductModal isOpen={isProductModalOpen} toggle={toggleProductModal} stations={stations} setProducts={setProducts} products={products} />
        </div>
        </>
    );

    return(
        "loading"
    )
};

const AddProductModal = ({ isOpen, toggle, stations, setProducts, products }) => {

    const [name, setName] = useState('');

    const [station, setStation] = useState('');

    const saveProduct = () => {
        if(!name || !station){
            return toast.error('Name and Station are required')
        }

        axios.post('/products', {
            name: name,
            stationId: station
        })
        .then(res => {
            toast.success('Saved Product')
            setProducts([
            ...products,
            res.data
        ])})
        .catch(err => console.log(err))
        .finally(() => preToggle())
    }

    const preToggle = () => {
        setName('')
        setStation('')
        toggle()
    }

    return(
        <Modal isOpen={isOpen} toggle={preToggle}>
            <ModalHeader toggle={preToggle}>
                Add Product
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                    <FormGroup>
                        <Label for="name">Product Name</Label>
                        <Input type="text" name="name" id="name"
                            placeholder='Product name...'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <FormGroup>
                        <Label for="name">Station</Label>
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        onChange={(e) => {
                            console.log(e.value)
                            setStation(e.value)}}
                        value={station ? {value: station, label: stations.find(option => option.id === station).name} : ''}
                        options={
                            stations.map(option => (
                                { value: option.id, label: option.name }
                            ))
                        }></Select>
                    </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter >
                <Button color="primary" onClick={saveProduct}>
                    Save
                </Button>{' '}
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default Products;