import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorFallbackModal from '../utilities/ErrorFallbackModal';
import { ErrorBoundary } from 'react-error-boundary';
import { UserContext } from '../context/UserContext';

const PrivateRoute = ({component: Component, ...rest}) => {

    const { logoutUser } = useContext(UserContext);

    const [loggedIn, setLoggedIn] = useState(false)

    const [loading, setLoading] = useState(true)

    function qbAuthFlow(token){
        axios.get(process.env.REACT_APP_SERVER_URL + '/authUri')
          .then(res => {
            window.location.href = res.data;
          })
          .catch(err => console.log(err))
          .finally(() => setLoading(false))
      }

    const isLoggedin = async () => {

        let gbToken = localStorage.getItem('GB_TOKEN');

        if(!gbToken){
            setLoggedIn(false)
        }

        try {
            await axios.get(process.env.REACT_APP_SERVER_URL + '/isTokenValid', {
                headers: {
                    'token': gbToken
                }
            })

            try{
                await axios.get(process.env.REACT_APP_SERVER_URL + '/isLoggedin', {
                    headers: {
                        'token': gbToken
                    }
                })

                setLoggedIn(true)

                
            } catch(err){
                debugger
                qbAuthFlow(gbToken)
                setLoading(false)
            }

            setLoading(false)

        } catch(err){
            logoutUser()
        }

        // axios.get(process.env.REACT_APP_SERVER_URL + '/isTokenValid', {
        //     headers: {
        //         'token': gbToken
        //     }
        // })
        // .then(res => {
        //     // check if server is logged in to QB
        //     axios.get(process.env.REACT_APP_SERVER_URL + '/isLoggedin', {
        //         headers: {
        //             'token': gbToken
        //         }
        //     })
        //     .then(res => setLoggedIn(true))
        //     .catch(err => {
        //         axios.get(process.env.REACT_APP_SERVER_URL + '/authUri', {
        //             headers:{
        //               token: gbToken
        //             }
        //           })
        //             .then(res => {
        //               window.location.href = res.data;
        //             })
        //             .catch(err => setLoggedIn(false))
        //             .finally(() => setLoading(false))
        //     })
        // })
        // .catch(err => {
        //     toast.error('Error')
        //     setLoggedIn(false)
        // })
        // .finally(() => setLoading(false))

        // await axios.get(process.env.REACT_APP_SERVER_URL + '/isTokenValid', {
        //     headers: {
        //         'token': gbToken
        //     }
        // })
        // .then(res => {
        //     // check if server is looged in to QB
        //     axios.get(process.env.REACT_APP_SERVER_URL + '/isLoggedin')
        //     .then(res => setLoggedIn(true))
        // })
        // .catch(err => {
        //     toast.error('Error')
        //     setLoggedIn(false)
        // })
        // .finally(() => setLoading(false))
    }

    useEffect(isLoggedin,[])

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            loading ?
            <div className="preloader">
                <div className="status">
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            :
            loggedIn ?
            <ErrorBoundary
                FallbackComponent={ErrorFallbackModal}
                >
            <Component {...props} />
            </ErrorBoundary>
            : 
            <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;