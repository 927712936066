import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

const Report = () => {

    const [invoices, setInvoices] = useState([])

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        axios.get('/getInvoices')
        .then(({data}) => {
            if (isEmpty(data.QueryResponse))
                throw new Error('No invoices for today')
            setInvoices(data.QueryResponse.Invoice)
            data.QueryResponse.Invoice.forEach(invoice => console.log(invoice.Line[0].Description, invoice.Line[0].SalesItemLineDetail?.Qty))
        })
        .catch(err => {
            toast.error(err.message)
        })
    }, [])

    let date = moment().format();

    console.log(invoices)

    return(
        <>
            <ul>
                {invoices.map(invoice => <li>{invoice.Line[0].Description} , {invoice.Line[0].SalesItemLineDetail?.Qty}</li>)}
            </ul>
        </>
    )
}

export default Report;