import React, { useEffect, useState, useRef, useContext } from 'react';
import { Card, Badge, Input, CardBody, Label, Button, Container, Col, Row, ListGroup, ListGroupItem, InputGroup, InputGroupAddon, FormGroup, CardHeader, CardFooter, Spinner } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import axios from 'axios';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import plusThick from '@iconify/icons-mdi/plus-thick';
import minusThick from '@iconify/icons-mdi/minus-thick';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import printerOutline from '@iconify/icons-mdi/printer-outline';
import syncIcon from '@iconify/icons-mdi/sync';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/airbnb.css";
import { Document, Page } from "react-pdf";
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import moment from 'moment';
import { UserContext } from '../context/UserContext';

global.Buffer = global.Buffer || require('buffer').Buffer

const LabelView = () => {

    const { gbToken } = useContext(UserContext)

    const [date, setDate] = useState('');

    const dateWithoutTime = (date) => {
        let splitDate = date.split('-');
        let modifiedDate = splitDate[1] + '-' + splitDate[2] + '-' + splitDate[0];
        let fullDate = new Date(modifiedDate);
        return fullDate.setHours(0, 0, 0, 0);
    }

    useEffect(() => {
        let today = new Date();
        today.setHours(0, 0, 0, 0)
        setDate([today, today])
    }, [])

    const [invoices, setInvoices] = useState([]);

    const [filteredInvoices, setFilteredInvoices] = useState([])

    const [activeLabel, setActiveLabel] = useState('');

    console.log("ACTIVE LABEL", activeLabel)

    useEffect(() => {
        if (filteredInvoices) {
            calculateTotalToPrint()
            if (filteredInvoices.find(invoice => invoice.Id === activeLabel.Id) === undefined) setActiveLabel('')
        }

    }, [filteredInvoices])

    const [invoice, setInvoice] = useState('')

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let today = moment().format(moment.HTML5_FMT.DATE);

        console.log(localStorage.getItem('GB_TOKEN'))

        axios.get(process.env.REACT_APP_SERVER_URL + '/getInvoices', {
            headers: {
                token: gbToken
            }
        })
            .then(({ data }) => {
                setInvoices(data.QueryResponse.Invoice)

                let tempFiltered = JSON.parse(JSON.stringify(data.QueryResponse.Invoice.filter(invoice => invoice.TxnDate == today)));

                const fixDate = (x) => {
                    let splitDate = x.split('-');
                    let modifiedDate = splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
                    return modifiedDate;
                }
                
                tempFiltered.forEach(label => {
                    label.tempLabelQty = 1;
                    label.TxnDate = fixDate(label.TxnDate);
                })

                setFilteredInvoices(tempFiltered)
            })
            .catch(err => {
                toast.error(`${err.response.status}: ${err.response.data.error_description}`)
            })
            .finally(() => setLoading(false))
    }, [])

    const [pdf, setPdf] = useState('');

    useEffect(() => {

        if (!activeLabel) return

        axios(process.env.REACT_APP_SERVER_URL + `/getinvoice/${activeLabel.Id}/pdf`)
            .then(res => {
                const encoded = Buffer.from(res.data.body.data).toString('base64')
                setPdf(encoded)



                // var obj = document.createElement('object');
                // obj.classList = 'd-none'
                // obj.style.width = '0';
                // obj.style.height = '0';
                // obj.type = 'application/pdf';
                // obj.data = 'data:application/pdf;base64,' + encoded;
                // document.body.appendChild(obj);

                // // Insert a link that allows the user to download the PDF file
                // var link = document.createElement('a');
                // link.classList = 'd-none';
                // link.style.width = 0;
                // link.style.height = 0;
                // link.download = 'dsadsa.pdf';
                // link.href = 'data:application/octet-stream;base64,' + encoded;
                // document.body.appendChild(link);
                // link.click();
            })
    }, [activeLabel])

    useEffect(() => {
        if (date.length === 2 && invoices.length > 0) {
            let tempFiltered = JSON.parse(JSON.stringify(invoices.filter(invoice => dateWithoutTime(invoice.TxnDate) >= date[0] && dateWithoutTime(invoice.TxnDate) <= date[1])));

                const fixDate = (x) => {
                    let splitDate = x.split('-');
                    let modifiedDate = splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
                    return modifiedDate;
                }
                
                tempFiltered.forEach(label => {
                    label.tempLabelQty = 1;
                    label.TxnDate = fixDate(label.TxnDate);
                })

            setFilteredInvoices(tempFiltered)
        }
    }, [date])

    const onLabelClick = (e, id) => {
        e.preventDefault()
        setPdf('')
        setActiveLabel(invoices.find(invoice => invoice.Id === id))
    }

    const labelsRef = useRef();

    const [labelsForPrint, setLabelsForPrint] = useState('')

    const prepareForPrint = () => {
        let labels = [];

        filteredInvoices.forEach(label => {
            if (label.tempLabelQty > 0) {
                for (let i = 0; i < label.tempLabelQty; i++) {
                    labels.push(label)
                }
            }
        })

        setLabelsForPrint(labels)

        console.log(labels, labels.length)
    }

    useEffect(() => {
        if (labelsForPrint)
            axios.post(process.env.REACT_APP_SERVER_URL + '/printLabels', labelsForPrint)
            .then(res => {
                console.log('PRINTEd')
                setLabelsForPrint('')
            })
            .catch(err => console.error(err))
    }, [labelsForPrint])

    console.log('LABELSFORPRINT', labelsForPrint)

    // const handlePrint = useReactToPrint({
    //     content: () => labelsRef.current,
    //     onAfterPrint: () => {
    //         setLabelsForPrint('')
    //     }
    // })

    const resetPrintQty = () => {

        let temp = [ ...filteredInvoices ]

        temp.forEach(label => {
            if (label.tempLabelQty > 0) label.tempLabelQty = 0
        })

        setFilteredInvoices(temp)
        setTotalToPrint(0);
    }

    const [totalToPrint, setTotalToPrint] = useState(0);

    const calculateTotalToPrint = () => {
        let total = 0;

        filteredInvoices.forEach(label => {
            if (label.tempLabelQty > 0) {
                total += label.tempLabelQty
            }
        })

        setTotalToPrint(total)
    }

    const prepareForPrintMultiplesOfOneLabel = (id, qty) => {
        let tempLabel = filteredInvoices.find(x => x.Id === id)
        let labels = [];
        for(let i = 0; i < qty; i++){
            labels.push(tempLabel)
        }

        setLabelsForPrint(labels)
    }

    const prepareToPrintOneLabel = (id) => {
        let tempLabel = filteredInvoices.find(x => x.Id === id)

        setLabelsForPrint([tempLabel])
    }

    return (
        <>
            <div className='w-100 m-0 d-flex align-items-center justify-content-between main-header' 
            style={{ height: '12vh'}}>
                <FormGroup className='mb-0 ml-3 d-flex'>
                    <Label className='text-white d-flex align-items-center mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>Date Range : </Label>
                    <InputGroup style={{ width: '350px' }}>
                        <FlatPicker
                            className='form-control rounded-left bg-white'
                            value={date}
                            onChange={date => setDate(date)}
                            options={
                                {
                                    maxDate: 'today',
                                    mode: "range",
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                }
                            }
                            placeholder="Choose date..."
                        ></FlatPicker>
                        <InputGroupAddon
                            addonType="append"
                        >
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={calendarRange} width="25px" />
                            </span>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            </div>
            <div style={{ height: '88vh', overflow: 'hidden' }}>
                <Card className='h-100'>
                    <Row className="h-100 d-flex flex-nowrap">
                        <Col sm={'auto'} className="pr-0 h-100 d-flex align-items-center shadow-lg">
                            <Card style={{ minWidth: '350px', width: '250px', height: '95%', borderRadius: '9px', overflow: 'hidden' }} 
                            className="mx-3 mb-0 shadow">
                                <CardHeader className='w-100 d-flex justify-content-end' style={{ top: '0px', background: '#CEE0EC' }}>
                                    <Button className='btn-icon mr-2 d-flex'
                                    onClick={resetPrintQty}
                                    color="dark"
                                    >
                                        Reset All
                                        <Icon icon={syncIcon} rotate={1} className='ml-1 mb-1' />
                                    </Button>
                                    <Button onClick={prepareForPrint} className='position-relative'
                                    disabled={!totalToPrint}
                                    color="dark"
                                    >
                                        Print All
                                        {(totalToPrint) ? <div className="position-absolute ml-1 rounded-circle d-flex justify-content-center align-items-center bg-danger"
                                        style={{ height: '20px', width: '20px', fontSize: '12px', top: '-10px', right: '-10px' }}
                                        >
                                            {totalToPrint}
                                        </div>
                                        :
                                        ''
                                        }
                                    </Button>
                                </CardHeader>
                                {filteredInvoices.length ?
                                    <SimpleBar className='label-simplebar'>
                                    <ListGroup style={{ borderRadius: '0' }}>
                                        {filteredInvoices.map((invoice, i) => (
                                            <ListGroupItem
                                                className={classnames('d-flex justify-content-between align-items-center', { active: activeLabel.Id === invoice.Id })}
                                                onClick={(e) => onLabelClick(e, invoice.Id)}
                                                key={invoice.Id}
                                            >
                                                <div className="w-100 h-100">
                                                    <div className='text-left'># {invoice.DocNumber}</div>
                                                    <div className='text-left'><strong>{invoice.TxnDate}</strong></div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                <div style={{ minWidth: '100px', height: '30px', borderRadius: '30px', overflow: 'hidden' }} 
                                                className='d-flex justify-content-between bg-light align-items-center'>
                                                    <Button color="light" style={{ height: '30px', width: '33px', borderRadius: '0' }} className='d-flex align-items-center justify-content-center'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            if(invoice.tempLabelQty <= 0) return
                                                            const temp = [...filteredInvoices]
                                                            temp[i].tempLabelQty = parseInt(temp[i].tempLabelQty) - 1
                                                            setFilteredInvoices(temp)
                                                            calculateTotalToPrint()
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                    <input className='qty-input border-0' style={{ maxHeight: '27px', maxWidth: '35px', textAlign: 'center' }}
                                                        type='number'
                                                        value={invoice.tempLabelQty}
                                                        on
                                                        onClick={e => e.stopPropagation()}
                                                        onChange={(e) => {
                                                            const temp = [...filteredInvoices]
                                                            temp[i] = {
                                                                ...temp[i],
                                                                tempLabelQty: parseInt(e.target.value)
                                                            }
                                                            setFilteredInvoices(temp)
                                                            calculateTotalToPrint()
                                                        }}
                                                    >

                                                    </input>
                                                    <Button color="light" style={{ height: '30px', width: '33px', borderRadius: '0' }} className='d-flex align-items-center justify-content-center'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            const temp = [...filteredInvoices]
                                                            temp[i].tempLabelQty = parseInt(temp[i].tempLabelQty) + 1
                                                            setFilteredInvoices(temp)
                                                            calculateTotalToPrint()
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </div>
                                                <Button color='light' className="btn-icon ml-2" size="sm"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    prepareForPrintMultiplesOfOneLabel(invoice.Id, invoice.tempLabelQty)
                                                }}
                                                disabled={!invoice.tempLabelQty}
                                                >
                                                    <Icon icon={printerOutline} width="18" height="18" />
                                                </Button>
                                                </div>
                                            </ListGroupItem>
                                        ))
                                    }
                                        {/* <ListGroupItem tag="a" href="#" action
                    // className={classnames({ active: activeTab === 'store' })}
                    // onClick={(e) => navigateSettings(e, 'store')}
                    >
                        <div className="w-100 h-100 d-flex">
                            <div className="h-100">
                            <Icon icon={storeIcon} color={activeTab === 'store' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />   
                            </div>
                            <div className="ml-1">
                            <div><strong>Store</strong></div>
                            <small className={activeTab === 'store' ? 'text-light' : "text-muted"}>View and update your store details</small>
                            </div>
                        </div>
                    </ListGroupItem> */}
                                    </ListGroup>
                                </SimpleBar>
                                :
                                <div className='w-100 no-results-message d-flex justify-content-center align-items-center'>
                                            <h5>There are no results for this date range</h5>
                                        </div>    
                            }
                                <CardFooter className='position-absolute w-100' style={{ bottom: '0px', textAlign: 'end', background: '#CEE0EC' }}>
                                    Total: <strong>{filteredInvoices.length}</strong>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col className='my-3 ml-2 mr-3'>
                            <Card className='w-100 h-100' style={{ borderRadius: '9px', overflow: 'hidden' }}>
                                <CardHeader className='d-flex justify-content-end' style={{ height: '63px', background: '#CEE0EC' }}>
                                    {/* <div className="d-flex shadow-sm px-2 py-1 mr-2" style={{ borderRadius: '30px', width: 'max-content', background: '#C6E8F2' }}>
                                        <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick mr-1"
                                            style={{ width: '40px', height: '40px' }}
                                            onChange={(e) => setActiveLabel({
                                                ...activeLabel,
                                                tempLabelQty: parseInt(activeLabel.tempLabelQty) - 1
                                            })}
                                        // onClick={() => setTempInventoryNumber(parseInt(tempInventoryNumber) -1) }
                                        >
                                            <Icon icon={minusThick} color="white" width="24" height="24" />
                                        </div>
                                        <Input
                                            style={{ maxWidth: '50px', textAlign: 'center' }}
                                            type="number"
                                            className="addClick"
                                            value={activeLabel.tempLabelQty}
                                            onChange={(e) => setActiveLabel({
                                                ...activeLabel,
                                                tempLabelQty: e.target.value
                                            })}
                                            placeholder="0"
                                        />
                                        <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick ml-1"
                                            style={{ width: '40px', height: '40px' }}
                                            onChange={(e) => setActiveLabel({
                                                ...activeLabel,
                                                tempLabelQty: parseInt(activeLabel.tempLabelQty) + 1
                                            })}
                                        >
                                            <Icon icon={plusThick} color="white" width="24" height="24" />
                                        </div>
                                    </div> */}
                                    <Button 
                                    onClick={() => prepareToPrintOneLabel(activeLabel.Id)}
                                    disabled={!activeLabel}
                                    color="dark"
                                    >
                                        <strong>Print Label</strong>
                                    </Button>
                                </CardHeader>
                                <CardBody style={{ overflow: 'auto' }}>
                                    {loading && 
                                        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                                        <div>
                                        <h4>Loading invoices...</h4>
                                            <div className="bouncing-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    {!loading && <>
                                    <Row className='mb-2'>
                                        <Col className='d-flex justify-content-center'>
                                            {/* {activeLabel &&
                                                <div className='text-center shadow-lg py-1 px-3' style={{ width: 'max-content', borderRadius: '8px' }}>
                                                    # {activeLabel.DocNumber} <br />
                                                    {activeLabel.ShipAddr.Line1}<br />
                                                    {activeLabel.ShipAddr.City}, {activeLabel.ShipAddr.CountrySubDivisionCode} {activeLabel.ShipAddr.PostalCode}
                                                </div>} */}
                                        </Col>
                                    </Row>
                                    {activeLabel && <Row className='d-flex justify-content-center'>
                                        <Col>
                                            <Document
                                                file={'data:application/pdf;base64,' + pdf}
                                                className='d-flex justify-content-center'
                                                loading={(
                                                    <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center mt-5'>
                                                    <div>
                                                    <h5>Rendering PDF...</h5>
                                                        <div className="bouncing-loader">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    )}
                                                    onLoadProgress={({ loaded, total }) => console.log('Loading a document: ' + (loaded / total) * 100 + '%')}
                                            // onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                <Page pageNumber={1} />
                                            </Document>
                                        </Col>
                                    </Row>}
                                    </>}
                                    {/* <div className='d-none'>
                                        <LabelsToPrint labels={labelsForPrint} ref={labelsRef} />
                                    </div> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}

// class LabelsToPrint extends React.Component {

//     constructor(props) {
//         super(props);
//     }

//     render() {
//         return (
//             <div>
//                 {this.props.labels && this.props.labels.map(label => (
//                     <div className='text-left printed-label' 
//                     style={{ pageBreakAfter: 'always', color: 'black', width: '100%', 
//                     height: '100%', textAlign: 'center', fontFamily: 'sans-serif', 
//                     paddingLeft: '3px', paddingTop: '7px', fontSize: '17px' }}>
//                         {/* # {label.DocNumber} <br />
//                         {label.ShipAddr?.Line1}<br />
//                         Apt. 3 <br />
//                         {label.ShipAddr?.City} */}
//                         {/* {label.ShipAddr?.CountrySubDivisionCode} {label.ShipAddr?.PostalCode}  */}
//                         #1041 <br />
//                         4581 Superlong Streetname <br />
//                         Apt. 300 <br />
//                         Superlong Cityname
//                     </div>
//                 ))}
//             </div>
//         )
//     }
// }

export default LabelView;