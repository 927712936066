import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

export const UserContext = createContext();

// export default function useUser(){
//     return useContext(UserContext)
// }

export const UserProvider = (props) => {

    const [gbToken, setGbToken] = useState(null);

    // const [userUpdated, setUserUpdated] = useState(false);

    const [userLoading, setUserLoading] = useState(true);

    let history = useHistory();
    let location  = useLocation();

    // useMemo(() => {
    //     let userToken = localStorage.getItem('FACI_TOKEN');
    // }, [])

    useEffect(() => {

        let localStorageGbToken = localStorage.getItem('GB_TOKEN');

        if(localStorageGbToken !== undefined){
            axios.get(process.env.REACT_APP_SERVER_URL + '/isTokenValid', {
                headers: {
                    'token': localStorageGbToken
                }
            })
            .then(res => {
                setGbToken(localStorageGbToken);
            })
            .catch(err => {
                setGbToken(null);
                localStorage.removeItem('GB_TOKEN');
                history.push('/login');
            })
            .finally(() => setUserLoading(false))
        } else {
            setUserLoading(false)
            setGbToken(null);
        }

    }, [])

    useEffect(() => {
        if(gbToken)
            history.push('/labels')
    }, [gbToken])

    const loginUser = async (loginInfo) => {
        setUserLoading(true);
        // const both = () => {
        //     setFaciToken('daskdbnasurhwifvbdsudnacbdvubj48723y432rhfksanckscnasuvdgauigewq');
        //     localStorage.setItem('FACI_TOKEN', 'daskdbnasurhwifvbdsudnacbdvubj48723y432rhfksanckscnasuvdgauigewq');
        //     setUserLoading(false)
        // }
        // setTimeout(() => both(), 2000)
        // setFaciToken('daskdbnasurhwifvbdsudnacbdvubj48723y432rhfksanckscnasuvdgauigewq');
        // setUserLoading(false)

        return new Promise(async (resolve, reject) => {
            try{

                const tokenRequest = await axios.post(process.env.REACT_APP_SERVER_URL + '/login', loginInfo)
                
                localStorage.setItem('GB_TOKEN', tokenRequest.data.token)
                toast.success('User Logged In');
                setGbToken(tokenRequest.data.token);
                // setFaciToken('daskdbnasurhwifvbdsudnacbdvubj48723y432rhfksanckscnasuvdgauigewq')
    
                resolve(tokenRequest.data.token)
                
            } catch(err){
                reject(err);
                toast.error('Error');
            } finally {   
                setUserLoading(false)
            }
        })
	}

    const logoutUser = () => {
        localStorage.removeItem('GB_TOKEN');
        setGbToken(null)
        toast.success('User Logged Out');
        history.push('/login');
    }

    // const logoutUser = () => {
    //     localStorage.removeItem('OMNI_TOKEN');
    //     OmniApi.defaults.headers.common['token'] = undefined;
    //     XOmniApi.defaults.headers.common['token'] = undefined;
    //     toast.success("User logged out", {
    //         theme: 'colored',
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    //     setUser('');
    //     history.push('/account/login');
    //     // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // } 


    
    return (
        <UserContext.Provider value={{ loginUser, logoutUser, gbToken }}>
            {props.children}
        </UserContext.Provider>
    );
}

