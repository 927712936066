import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@iconify/react';
import { Card, CardBody, Row, Col, InputGroup, InputGroupAddon, Button, Label } from 'reactstrap';
import arrowDownBold from '@iconify/icons-mdi/arrow-down-bold';
import closeIcon from '@iconify/icons-mdi/close';
import dragIcon from '@iconify/icons-mdi/drag';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash.clonedeep';

const ItemGroupDetail = () => {

    const [groupItems, setGroupItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedGroupItem, setSelectedGroupItem] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get('/items')
        .then(res => {
            res.data.forEach(row => {
                if (!row.products)
                row.products = []
            })
            setGroupItems(res.data)
        })
        .catch(e => console.log(e))

        axios.get('/products')
        .then(res => {
            setProducts(res.data)
        })
        .catch(e => console.log(e))

    }, [])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const previousDNDState = useRef()

    const previousSaveItemGroupAxiosToken = useRef()

    const toastId = React.useRef(null);

    const saveSelectedItemGroup = async (item) => {

        // if(!previousDNDState.current)
        //     previousDNDState.current = item

        if(previousSaveItemGroupAxiosToken.current)
            previousSaveItemGroupAxiosToken.current.cancel();

        previousSaveItemGroupAxiosToken.current = axios.CancelToken.source();  

        try{
            const saveSelectedItem = await axios.put(`/items/${selectedGroupItem.id}`, item, { cancelToken: previousSaveItemGroupAxiosToken.current.token }) 

            setGroupItems([
                ...groupItems.filter(groupItem => groupItem.id !== item.id),
                item
            ])

            // if(toastId.current)
            //     toast.dismiss(toastId.current);

            // toastId.current = toast.success('Saved');
            toast.success('Saved');

            previousDNDState.current = cloneDeep(saveSelectedItem.data)

            return true
        } catch(err){
            if (axios.isCancel(err)) {
                console.log('Previous request canceled, new request is sent', err.message);
                return true
            }
            toast.error('Error');
            throw new Error('Error')
        }
    }

    const handleOnDragEnd = async result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(selectedGroupItem.products, source.index, destination.index);

            try{

                setSelectedGroupItem({
                    ...selectedGroupItem,
                    products: items
                })

                await saveSelectedItemGroup({
                    ...selectedGroupItem,
                    products: items
                })

                let newGroupItems = [
                    ...groupItems
                ]

                let newIndex = groupItems.findIndex(item => item.id === selectedGroupItem.id)

                newGroupItems[newIndex] = {
                    ...selectedGroupItem,
                    products: items
                }

                setGroupItems(newGroupItems)

            } catch(err){
                setSelectedGroupItem(previousDNDState.current)
            }

        } else {
            return
        }
    }
    

    const changeQty = async (product, i, action, value = '') => {

        let temp;

        if(action === 'subtract'){
            if(product.qty <= 0) return
            temp = cloneDeep(selectedGroupItem.products)
            // temp = [...selectedGroupItem.products]
            temp[i].qty = parseInt(temp[i].qty) - 1
            setSelectedGroupItem({
                ...selectedGroupItem,
                products:[
                    ...temp
                ]
            })
        } else if(action === 'set'){
            temp = [...selectedGroupItem.products]
            temp[i] = {
                ...temp[i],
                qty: parseInt(value)
            }
            setSelectedGroupItem({
                ...selectedGroupItem,
                products:[
                    ...temp
                ]
            })
        } else if (action === 'add'){
            temp = cloneDeep(selectedGroupItem.products)
            // temp = [...selectedGroupItem.products]
            temp[i].qty = parseInt(temp[i].qty) + 1
            setSelectedGroupItem({
                ...selectedGroupItem,
                products:[
                    ...temp
                ]
            })
        }

        try {
            await saveSelectedItemGroup({
                ...selectedGroupItem,
                products:[
                    ...temp
                ]
            })

            let newGroupItems = [
                ...groupItems
            ]

            let newIndex = groupItems.findIndex(item => item.id === selectedGroupItem.id)

            newGroupItems[newIndex] = {
                ...selectedGroupItem,
                products:[
                    ...temp
                ]
            }

            setGroupItems(newGroupItems)

        } catch(err){
            setSelectedGroupItem(previousDNDState.current)
        }
    }

    // console.log(selectedGroupItem.products.map(itemProduct => products.find(product => product.id === itemProduct)))

    const onItemSelectChange = (select) => {
        setSelectedGroupItem(...select)
        previousDNDState.current = cloneDeep(select[0])
    }
    
    const onProductSelectChange = async (select) => {
        if(!select.length) return
        

        let exists = false;

        selectedGroupItem.products.forEach(product => {
            if(product.id === select[0].id)
                return exists = true;
        })

        if(exists === true){
            toast.error('Product has already been added to Item')
            return setTimeout(() => {
                selectedProductsTypeaheadRef.current.clear()
            }, 250)}
        
        select[0].qty = 1;

        setSelectedGroupItem({
            ...selectedGroupItem,
            products:[
                ...select,
                ...selectedGroupItem.products
            ]
        })

        setTimeout(() => {
            selectedProductsTypeaheadRef.current.clear()
        }, 250)

        try {
            await saveSelectedItemGroup({
                ...selectedGroupItem,
                products:[
                    ...select,
                    ...selectedGroupItem.products
                ]
            })

            let newGroupItems = [
                ...groupItems
            ]

            let newIndex = groupItems.findIndex(item => item.id === selectedGroupItem.id)

            newGroupItems[newIndex] = {
                ...selectedGroupItem,
                products:[
                    ...select,
                    ...selectedGroupItem.products
                ]
            }

            setGroupItems(newGroupItems)

        } catch(err){
            setSelectedGroupItem(previousDNDState.current)
        }
    }

    const removeGroupItemProduct = async (id) => {

        setSelectedGroupItem({
            ...selectedGroupItem,
            products: selectedGroupItem.products.filter(product => product.id !== id
        )})

        try {
            await saveSelectedItemGroup({
                ...selectedGroupItem,
                products: selectedGroupItem.products.filter(product => product.id !== id
            )})

            let newGroupItems = [
                ...groupItems
            ]

            let newIndex = groupItems.findIndex(item => item.id === selectedGroupItem.id)

            newGroupItems[newIndex] = {
                ...selectedGroupItem,
                products: selectedGroupItem.products.filter(product => product.id !== id
            )}

            setGroupItems(newGroupItems)

        } catch(err){
            setSelectedGroupItem(previousDNDState.current)
        }
    }

    const selectedProductsTypeaheadRef = useRef(null);

    return(
        <Row>
            <Col style={{ minHeight: '75vh', width: '75vw' }} className='d-flex justify-content-center align-items-center'>
                <Card className='h-100 w-100 mt-5 mx-5'>
                    <CardBody>
                        {/* <Row>
                            <Col className="d-flex justify-content-between">
                            <h3>Total Products</h3>
                            <div>Total: <strong>{selectedGroupItem?.products?.length}</strong></div> 
                            </Col>
                        </Row> */}
                        <Row>
                                <Col className='d-flex justify-content-center'>
                                    <Label>
                                            Quickbooks Items:
                                        </Label>
                                </Col>
                            </Row>
                        <Row>
                            <Col>
                                <InputGroup  className="mb-2 d-flex justify-content-center">
                                    <div style={{ width: '350px' }}>
                                    <Typeahead
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="name"
                                    multiple={false}
                                    options={groupItems.map(product => product)}
                                    // selected={[selectedGroupItem]}   
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onItemSelectChange}
                                    placeholder="Add products to group item..."
                                    />
                                    </div>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr />
                            <Row className='d-flex justify-content-center'>
                            <Col>
                            <Row>
                                <Col>

                                </Col>
                                <Col className='d-flex justify-content-center'>
                                    <Label>
                                            Greenbowl Products:
                                        </Label>
                                </Col>
                                <Col className='d-flex justify-content-end align-items-center'>
                                    <div className='my-0 mr-2'>
                                        Total Products in Group: 
                                    </div>
                                    <h5 className='my-0'>
                                        {selectedGroupItem?.products?.length}
                                    </h5>
                                </Col>
                            </Row>
                                        <Row>
                                        <Col>
                                        <InputGroup  className="mb-2 d-flex justify-content-center">
                                            <div style={{ width: '250px' }}>
                                            <Typeahead
                                            ref={selectedProductsTypeaheadRef}
                                            paginate
                                            maxResults={15}
                                            id="select2"
                                            labelKey="name"
                                            multiple={false}
                                            options={products.map(product => product)}
                                            // selected={[selectedGroupItem]}   
                                            // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                            onChange={onProductSelectChange}
                                            placeholder="Add products to group item..."
                                            disabled={!selectedGroupItem}
                                            />
                                            </div>
                                            <InputGroupAddon addonType="append">
                                                <span className="input-group-text py-0 px-1" >
                                                    <Icon icon={arrowDownBold} width="25" height="25" />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                        </Row>
                                        <Row className='d-flex justify-content-center'>
                                    <Col lg={8}>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable className="d-flex" droppableId='products'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {selectedGroupItem?.products?.map((product, i) => (
                                        <Draggable key={`${product.id}`} draggableId={`${product.id}`} index={i} className="d-flex">
                                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                            <Card className="mb-0">
                                                <Row>
                                                    <Col className="d-flex align-items-center">
                                                <div className="bg-light h-100 rounded-left" style={{ width: '60px' }} {...provided.dragHandleProps}>
                                                    <Icon icon={dragIcon} width="60" height="80" />
                                                </div>
                                                <div className="h-100" style={{ width: '80px', overflow: 'hidden', minWidth: "80px" }}>
                                                    <img 
                                                    src="http://dummyimage.com/80"
                                                    ></img>
                                                </div>
                                                <div 
                                                style={{ flex: 1, overflow: 'hidden', maxHeight: '80px' }}
                                                className="h-100 ml-3 d-flex flex-column justify-content-around">
                                                    <div style={{ overflow: 'hidden' }}><strong>{product.name}</strong></div>
                                                </div>
                                                <div style={{ minWidth: '100px', height: '30px', borderRadius: '30px', overflow: 'hidden' }} 
                                                className='d-flex justify-content-between bg-light align-items-center mr-2'>
                                                    <Button color="light" style={{ height: '30px', width: '33px', borderRadius: '0' }} className='d-flex align-items-center justify-content-center'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            changeQty(product, i, 'subtract')
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                    <input className='qty-input border-0' style={{ maxHeight: '27px', maxWidth: '35px', textAlign: 'center' }}
                                                        type='number'
                                                        value={product.qty}
                                                        onClick={e => e.stopPropagation()}
                                                        onChange={(e) => {
                                                            const temp = [...selectedGroupItem.products]
                                                            changeQty(product, i, 'set', e.target.value)
                                                        }}
                                                    >

                                                    </input>
                                                    <Button color="light" style={{ height: '30px', width: '33px', borderRadius: '0' }} className='d-flex align-items-center justify-content-center'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            changeQty(product, i, 'add')
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </div>
                                                <div className="btn-danger btn-icon delete-btn-icon position-absolute rounded-circle d-flex justify-content-center align-items-center" 
                                                style={{ height: '25px', width: '25px', right: '3px', top: '-7px' }}
                                                onClick={() => removeGroupItemProduct(product.id)}
                                                >
                                                    <Icon icon={closeIcon} width="15" height="15" color="white" className="delete-btn-icon" />
                                                </div>
                                                </Col>
                                                </Row>
                                            </Card>
                                            </div>)}</Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                                    </Col>
                                </Row>
                                </Col>
                                </Row>
                        {/* <Row>
                            <Col md={12}>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable className="d-flex" droppableId='products'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {collection.products?.map((product, i) => (
                                        <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                            <Card className="mb-0">
                                                <Row>
                                                    <Col className="d-flex">
                                                <div className="bg-light h-100 rounded-left" style={{ width: '60px' }} {...provided.dragHandleProps}>
                                                    <Icon icon={dragIcon} width="60" height="80" />
                                                </div>
                                                <div className="h-100" style={{ width: '80px', overflow: 'hidden', minWidth: "80px" }}>
                                                    <img 
                                                    src="http://dummyimage.com/80"
                                                    ></img>
                                                </div>
                                                <div 
                                                style={{ flex: 1, overflow: 'hidden', maxHeight: '80px' }}
                                                className="h-100 ml-3 d-flex flex-column justify-content-around">
                                                    <div style={{ overflow: 'hidden' }}><strong>{truncateString(product.prodName, 100)}</strong></div>
                                                </div>
                                                <div className="btn-danger btn-icon delete-btn-icon position-absolute rounded-circle d-flex justify-content-center align-items-center" 
                                                style={{ height: '25px', width: '25px', right: '3px', top: '-7px' }}
                                                onClick={() => removeCollectionProduct(product.prodToken)}
                                                >
                                                    <Icon icon={closeIcon} width="15" height="15" color="white" className="delete-btn-icon" />
                                                </div>
                                                </Col>
                                                </Row>
                                            </Card>
                                            </div>)}</Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                            </Col>
                        </Row> */}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )

}

export default ItemGroupDetail;